import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

const AddEventCalendarSection = loadable(() => pMinDelay(import('./AddEventCalendarSection'), 1000), {
  fallback: <div className='bg-white pt-6 pb-12'><p className=' font-display italic text-2xl md:text-3xl text-gray-900 text-center tracking-wider leading-tight'>Loading Upcoming Training Calendar...</p></div>,
})

const TrainingSection = () => (
  <div className='bg-white pt-12 pb-16 md:min-h-screen'>
    <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto mb-8'>
      <h1 className='font-display text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>Upcoming Training</h1>
    </div>

    <AddEventCalendarSection />
  </div>
)

export default TrainingSection