import React from "react"
import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import TrainingSection from "../components/sections/TrainingSection"

const UpcomingTrainingPage = () => (
  <MainContainer>
    <HeadData
      title='Training'
      description='List of training events from Command Access.'
    />

    <div>
      <TrainingSection />
    </div>
  </MainContainer>
)

export default UpcomingTrainingPage